.asg-footer {
  padding-bottom: 50px;
  padding-top: 100px;
  background: #000;
  position: relative;
  z-index: 1;
  &-firms {
    justify-content: space-between;
    padding: 25px 0;
    flex-wrap: inherit;
    .item {
      padding: 10px;
      flex-grow: inherit;
      .image {
        max-width: 150px;
      }
    }
  }

  &.not-home {
    margin-top: 0;
  }

  .footer-top-wrapper {
    .address-bar {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      .title {
        font-size: 45px;
        font-weight: bold;
        text-align: left;
        color: #fff;
      }
      .address {
        font-size: 15px;
        text-align: left;
        max-width: 90%;
        color: #fff;
      }
      .mail {
        font-size: 15px;
        text-align: left;
        color: #fff;
      }
    }
    .iframe-wrapper {
      iframe {
        width: 100%;
        height: 380px;
        border: none;
        border-radius: 40px;
      }
    }
  }
  .footer-middle-wrapper {
    margin-top: 100px;
    .footer-logo-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .info {
        text-align: left;
        font-size: 16px;
        color: #4e4e4e;
      }
      .image {
        height: 40px;
        margin-bottom: 30px;
      }
      .language-wrapper {
        margin: 0;
        padding: 0;
        list-style: disc;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #3e3e3e;
        li {
          margin: 0;
          float: left;
          margin-right: 25px;
          padding-right: 3px;
          text-transform: uppercase;
          list-style: disc;
          color: #fff;
          button {
            color: #fff;
            background: transparent;
            padding: 0;
            text-transform: uppercase;
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
          }
        }

        & > li:first-child {
          list-style: none;
        }
      }
    }
    .footer-menu-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      div {
        display: flex;
        flex-direction: column;
        margin-right: 8%;
        &:last-child {
          margin-right: 0;
        }
        & > a,
        .title {
          color: #838383;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          white-space: nowrap;
        }
        ul {
          padding: 0;
          margin: 0;
          margin-top: 12px;

          li {
            list-style: none;
            text-align: left;
            margin-bottom: 12px;
            a {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }
    }
    &.not-home {
      margin-top: 0 !important;
    }
  }
  .footer-bottom-wrapper {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #4e4e4e;
    .asg-wrapper {
      font-size: 14px;
      display: flex;
      align-items: center;
      .asgLogo {
        width: 60px;
      }
      span {
        margin: 0 10px;
      }
    }
    .copy-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        margin: 0 10px;
        font-size: 14px;
        color: #fff;
        align-items: center;
      }
    }
    .policy-wrapper {
      display: flex;
      justify-content: flex-end;
      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        align-items: center;

        li {
          margin: 0;
          border-left: 1px solid #838383;
          padding-left: 10px;
          margin-left: 10px;
          &:first-child {
            border-left: none;
          }
          a {
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
  }
  &-reverse {
    background-color: #fff;
    color: #000 !important;
    a,
    .title {
      color: #000 !important;
    }
    .footer-middle-wrapper {
      .footer-logo-wrapper {
        .language-wrapper {
          li {
            color: light-neutral(0);
            button {
              color: light-neutral(0);
            }
          }
        }
      }
    }
    .footer-bottom-wrapper {
      .copy-wrapper {
        span {
          color: #000;
        }
      }
    }
  }
}
.social-media {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .asg-footer .footer-top-wrapper .address-bar {
    .title {
      font-size: 35px;
    }
    .address {
      font-size: 12px;
    }
  }
  .asg-footer-firms {
    .item {
      padding: 5px !important;
      .image {
        max-width: 60px !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .asg-footer {
    margin-top: 60px !important;
    padding-top: 0;
    text-align: center;
    &-firms {
      justify-content: center;
      flex-wrap: wrap;
      .item {
        .image {
          height: 26px;
        }
      }
    }
    .footer-top-wrapper {
      .address-bar {
        .title {
          font-size: 25px;
          margin-bottom: 20px;
        }
        .address {
          font-size: 12px;
          margin-bottom: 20px;
        }
        span {
          margin-bottom: 5px;
        }
      }
    }
    .footer-middle-wrapper {
      margin-top: 30px;
      .footer-logo-wrapper {
        align-items: center;
        .info {
          text-align: center;
          font-size: 14px;
        }
        .language-wrapper {
          margin-bottom: 30px;
          li {
            &:last-child {
              margin: 0;
            }
          }
        }
      }
      .footer-menu-wrapper {
        display: none;
      }
    }
    .footer-bottom-wrapper {
      margin-top: 0;
      .asg-wrapper {
        justify-content: center;
        flex-wrap: wrap;
        .asgLogo {
          margin-bottom: 15px;
        }
      }
      .copy-wrapper {
        justify-content: center;
        align-items: center;
        span {
          // margin: 0 !important;
          font-size: 12px;
          margin: 0 10px;
        }
      }
      .policy-wrapper {
        ul {
          flex-direction: row;
          padding: 0;
          flex-wrap: wrap;
          justify-content: center;

          li {
            width: 33.3%;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            border-left: none;
          }
        }
      }
    }
  }
  .social-media {
    justify-content: center;
  }
}
