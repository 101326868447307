.asg-main-slide-show {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  border-radius: 0px;
  pointer-events: all;
  overflow: hidden;
  z-index: 1;
  transition: all, 0.1s;
  margin-bottom: 50px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;

  .footer-social-media-list {
    color: #fff;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    left: 50%;
    bottom: 2.8rem;
    z-index: 200;
    padding: 8px;
    border: 0;
    color: var(--text);
    cursor: pointer;
    outline: none;
    transform: translateY(-50%);
    background-color: transparent;

    &.slick-prev {
      margin-left: calc(-50% + 90px);
    }

    &.slick-next {
      margin-left: calc(-50% + 120px);
    }

    .icon {
      font-size: 1.5rem;
    }
  }

  .slick-slide > div {
    width: 100%;
    height: 100%;
  }

  .container-extended {
    position: relative;
    z-index: 10;
    display: flex;
    height: 100%;
    align-content: center;
  }

  &-screen {
    position: relative;
    height: 100%;
    text-decoration: none;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      display: block;
      z-index: 2;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, var(--dark) 0%, transparent 100%);
      pointer-events: none;
    }
  }

  &-backdrop {
    top: 0;
    left: 0;
    z-index: 1;

    img {
      object-fit: cover;
    }
  }

  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &-poster {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all, 0.4s;
      object-fit: cover;
      z-index: 2;
      opacity: 1;
      pointer-events: none;

      &--fade {
        opacity: 0;
        transition-delay: 0.3s;
      }
    }

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 140px;
    box-sizing: border-box;
    z-index: 100;
    bottom: 0%;
    z-index: 4;
    &-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      bottom: 40px;
      padding: inherit;
      align-items: center;
      position: absolute;
      left: 0;
      box-sizing: border-box;
      .title {
        font-size: 16px;
        color: #fff;
        margin-right: 20px;
        font-weight: bold;
        line-height: 30px;
      }
      .image {
        width: 92px;
      }
      .down-bottom {
        border: none;
        background: rgba($color: #838383, $alpha: 0.3);
        color: #fff;
        border-radius: 100%;
        width: 72px;
        height: 72px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        padding: 0;
      }
    }
  }

  &-tools {
    z-index: 4;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &-dot {
    background: none;
    border: none;
    text-align: left;
    display: flex;
    flex-direction: row;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    margin-bottom: 14px;
    position: relative;
    // padding-left: 134px;
    opacity: 0.5;
    align-items: center;

    &-firms {
      display: flex;
      flex-direction: row;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 2px solid #ebebeb;
      a {
        margin-right: 26px;
        display: flex;
        align-items: center;
        .image {
          height: 35px;
        }
      }
    }

    &--active {
      font-size: 45px;
      font-weight: bold;
      opacity: 1;
      display: none;
      &::before {
        // width: 140px;
        // height: 3px;
        // background: #838383;
        // content: '';
        // position: absolute;
        // top: 47%;
        // left: -140px;
      }
    }
  }
}

@media #{$media-lg} {
  .asg-main-slide-show {
    &-video {
      object-position: 0px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .asg-main-slide-show {
    .down-bottom {
    }

    &-caption {
      padding: 0px 65px;
      &-footer {
        img {
        }
      }
    }
    &-dot {
      font-size: 17px;

      &::before {
        width: 65px;
        left: -65px;
      }
      &-firms {
        a {
          .image {
            height: 30px;
          }
        }
      }

      &--active {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}

@media #{$media-sm} {
  .marquee-slider {
    .image {
      height: 30px;
      margin-right: 15px;
    }
  }

  .asg-main-slide-show {
    $root: &;
    margin-bottom: 10px;
    .down-bottom {
      width: 40px;
      height: 40px;
    }

    &-caption {
      width: 100%;
      padding: 0 30px;
      &-footer {
        width: 100%;
        img {
          height: 30px;
        }
        .title {
          font-size: 10px;
          margin-right: 7.5px;
        }

        .c-icon {
          font-size: 20px;
        }
      }
    }
    &-dot {
      font-size: 17px;

      &::before {
        width: 0;
        height: 3px;
        background: #838383;
        content: '';
        position: absolute;
        top: 44%;
        left: -30px;
        transition: all, 0.4s;
      }
      &-firms {
        width: 50%;
        a {
          .image {
            height: 30px;
          }
        }
      }
      &--active {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        &::before {
          width: 30px;
          transition: all, 0.4s;
        }
      }
    }
  }
}

// @media #{$media-xs} {
//   .asg-main-slide-show {
//     $root: &;
//     max-height: -webkit-fill-available;

//     &-video,
//     &-backdrop {
//       object-position: inherit;
//     }

//     .slick-arrow {
//       display: none !important;
//     }

//     &-caption {
//       &-footer {
//       }
//     }
//   }
// }
